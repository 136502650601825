import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { UserManagementOverviewComponent } from '../../../domains/organization/user/components/user-management-overview/user-management-overview.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { DataProcessingAgreementComponent } from '../../../domains/organization/organization/components/data-processing-agreement/data-processing-agreement.component';
import { UserSettingsComponent } from '../../../domains/organization/user/components/user-settings/user-settings.component';
import { SubscriptionSettingsComponent } from '../../../domains/payment/subscription/components/subscription-settings/subscription-settings.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgComponentOutlet } from '@angular/common';
import { startWith } from 'rxjs/operators';
import { Subject, takeUntil } from 'rxjs';

interface Tab {
  key: string;
  component: Type<unknown>;
  wrapperClass?: string;
}

@Component({
  selector: 'app-settings-container',
  standalone: true,
  imports: [MatTabsModule, RouterModule, TranslocoModule, NgComponentOutlet],
  templateUrl: './settings-container.component.html',
})
export class SettingsContainerComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject<void>();

  readonly tabs: Tab[] = [
    {
      key: 'employees',
      component: UserManagementOverviewComponent,
      wrapperClass: 'w-5/6',
    },
    {
      key: 'generalSettings',
      component: UserSettingsComponent,
    },
    {
      key: 'billing',
      component: SubscriptionSettingsComponent,
      wrapperClass: 'pl-2',
    },
    {
      key: 'dataProcessing',
      component: DataProcessingAgreementComponent,
      wrapperClass: 'w-9/12',
    },
  ];

  readonly defaultTabIndex = 0;
  selectedTabIndex = 0;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  onTabChange(tabIndex: number): void {
    if (this.selectedTabIndex === tabIndex) {
      return;
    }
    if (this.tabs[tabIndex]) {
      void this.router.navigate([], {
        queryParams: { tab: this.tabs[tabIndex].key },
        queryParamsHandling: 'merge',
      });
    }
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(startWith(this.route.snapshot.queryParams))
      .pipe(takeUntil(this.destroy$))
      .subscribe((queryParams) => {
        const tabIndex = this.tabs.findIndex((tab: Tab) => tab.key === queryParams['tab']);
        this.selectedTabIndex = tabIndex !== -1 ? tabIndex : this.defaultTabIndex;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
