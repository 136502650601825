import { Routes } from '@angular/router';
import { OverviewContainerComponent } from './pages/portal-container/overview-container/overview-container.component';
import { SettingsContainerComponent } from './pages/portal-container/settings-container/settings-container.component';
import { CourseDetailComponent } from './pages/portal-container/course-detail/course-detail.component';
import { RouteAwarePortalDialogComponent } from './pages/portal-container/route-aware-portal-dialog/route-aware-portal-dialog.component';
import { MsalGuard } from '@azure/msal-angular';
import { userDataResolver } from './core/resolvers/user-data.resolver';
import { CustomerOverviewComponent } from './pages/sep-admin-container/customer-overview/customer-overview.component';
import { LearningPlanDetailComponent } from './pages/portal-container/learning-plan-detail/learning-plan-detail.component';
import { roleGuard } from './core/guards/role-guard';
import { detailsPageGuard } from './core/guards/details-page-guard';

function getPortalRouteChildren(): Routes {
  return [
    {
      path: 'overview',
      component: OverviewContainerComponent,
    },
    {
      path: 'courses/:id',
      component: CourseDetailComponent,
      children: [
        {
          path: 'dialog',
          component: RouteAwarePortalDialogComponent,
        },
      ],
      canActivate: [detailsPageGuard],
    },
    {
      path: 'learningPlans/:id',
      component: LearningPlanDetailComponent,
      children: [
        {
          path: 'dialog',
          component: RouteAwarePortalDialogComponent,
        },
      ],
      canActivate: [detailsPageGuard],
    },
    {
      path: 'settings',
      component: SettingsContainerComponent,
      children: [
        {
          path: 'dialog',
          component: RouteAwarePortalDialogComponent,
        },
      ],
    },
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'overview',
    },
    {
      path: '**',
      redirectTo: 'overview',
    },
  ];
}

function getAdminRouteChildren(): Routes {
  return [
    {
      path: 'customers',
      component: CustomerOverviewComponent,
    },
  ];
}

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'portal',
  },
  {
    path: 'register',
    loadComponent: async () =>
      import('./pages/registration-container/registration-container.component').then(
        (c) => c.RegistrationContainerComponent,
      ),
  },
  {
    path: 'portal',
    loadComponent: async () =>
      import('./pages/portal-container/portal-container.component').then((c) => c.PortalContainerComponent),
    canActivate: [MsalGuard],
    resolve: {
      userData: userDataResolver,
    },
    children: getPortalRouteChildren(),
  },
  {
    path: 'admin',
    loadComponent: async () =>
      import('./pages/sep-admin-container/sep-admin-container.component').then((c) => c.SepAdminContainerComponent),
    resolve: {
      userData: userDataResolver,
    },
    canActivate: [MsalGuard, roleGuard],
    children: getAdminRouteChildren(),
  },
  {
    path: '**',
    component: OverviewContainerComponent,
  },
];
