import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogViewComponent } from './dialog.component';
import { TemplateRef } from '@angular/core';

interface ButtonRowData {
  confirmButtonText: string;
  confirmButtonAction: () => void;
  confirmButtonDisabled?: boolean;
  declineButtonText: string;
  confirmButtonLoading?: boolean;
}

export class DialogModel {
  headline?: string;
  closable: boolean;
  description?: string;
  linkDescription?: string;
  linkHref?: string;
  secondDescription?: string;
  secondLinkDescription?: string;
  secondLinkHref?: string;
  thirdDescription?: string;
  footerDescription?: string;
  footerLinkDescription?: string;
  confirmButtonText?: string;
  confirmButtonAction?: () => void;
  topButtonFooter?: {
    text: string;
    action: () => void;
    isLoading?: boolean;
    disabled?: boolean;
  };
  footerLinkAction?: () => void;
  additionalFooterDescription?: string;
  additionalFooterLinkDescription?: string;
  additionalFooterLinkAction?: () => void;
  buttonRowData?: ButtonRowData;
  contentTemplate?: TemplateRef<Element>;

  private dialogRef?: MatDialogRef<DialogViewComponent>;

  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    this.dialogRef = this.dialog.open(DialogViewComponent, {
      disableClose: !this.closable,
      maxWidth: '600px',
      data: {
        headline: this.headline,
        closable: this.closable,
        description: this.description,
        linkDescription: this.linkDescription,
        linkHref: this.linkHref,
        secondDescription: this.secondDescription,
        secondLinkDescription: this.secondLinkDescription,
        secondLinkHref: this.secondLinkHref,
        thirdDescription: this.thirdDescription,
        footerDescription: this.footerDescription,
        footerLinkDescription: this.footerLinkDescription,
        footerLinkAction: this.footerLinkAction,
        additionalFooterDescription: this.additionalFooterDescription,
        additionalFooterLinkDescription: this.additionalFooterLinkDescription,
        additionalFooterLinkAction: this.additionalFooterLinkAction,
        buttonRowData: this.buttonRowData,
        confirmButtonText: this.confirmButtonText,
        confirmButtonAction: this.confirmButtonAction,
        topButtonFooter: this.topButtonFooter,
        contentTemplate: this.contentTemplate,
      },
    });
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
