import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserService } from './services/user.service';
import { tap } from 'rxjs/operators';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';
import { AuthService } from '../../services/auth.service';
import { SepImpersonationSessionData, SepSessionStorageKeys } from '../../services/session-storage/SepSessionStorage';
import { UserRole, CurrentUserUIModel } from './models/CurrentUserUIModel';

export interface CurrentUserStateModel {
  user: CurrentUserUIModel | null;
}

export class GetCurrentUserData {
  static readonly type = '[User] Get User Data';
}

@State<CurrentUserStateModel>({
  name: 'userData',
  defaults: {
    user: null,
  },
})
@Injectable()
export class CurrentUserState {
  constructor(
    private readonly sessionStorageService: SessionStorageService,
    private readonly userService: UserService,
    private readonly authService: AuthService,
  ) {}

  @Selector()
  static user(state: CurrentUserStateModel) {
    return state.user;
  }

  @Selector()
  static userDetails(state: CurrentUserStateModel) {
    return {
      username: state.user?.username,
      email: state.user?.email,
    };
  }

  @Selector()
  static hasOpenPurchaseIntent(state: CurrentUserStateModel) {
    return state.user?.purchaseIntent ?? false;
  }

  @Selector()
  static purchaseIntent(state: CurrentUserStateModel) {
    return state.user?.purchaseIntent ?? null;
  }

  @Selector()
  static purchaseIntentId(state: CurrentUserStateModel) {
    return state.user?.purchaseIntent?.id;
  }

  @Selector()
  static organizationId(state: CurrentUserStateModel) {
    return state.user?.organization?.id ?? undefined;
  }

  @Selector()
  static subscriptionId(state: CurrentUserStateModel) {
    return state.user?.organization?.subscriptions[0]?.id ?? undefined;
  }

  @Selector()
  static subscriptionBillingPeriod(state: CurrentUserStateModel) {
    return {
      createdAt: state.user?.organization?.subscriptions[0]?.createdAt,
      expiresAt: state.user?.organization?.subscriptions[0]?.expiresAt,
    };
  }

  @Selector()
  static tenantId(state: CurrentUserStateModel) {
    return state.user?.organization?.tenant?.id ?? undefined;
  }

  @Selector()
  static numberOfLicences(state: CurrentUserStateModel) {
    return state.user?.organization?.subscriptions[0]?.numberOfLicenses ?? 0;
  }

  @Selector()
  static subscription(state: CurrentUserStateModel) {
    return state.user?.organization?.subscriptions[0] ?? null;
  }

  @Action(GetCurrentUserData)
  async getUserData(ctx: StateContext<CurrentUserStateModel>) {
    const isSepAdmin = await this.authService.isSepAdmin();
    if (!isSepAdmin) {
      return this.getOrgAdminUserData(ctx);
    } else {
      return this.getSepAdminUserData(ctx);
    }
  }

  private getOrgAdminUserData(ctx: StateContext<CurrentUserStateModel>) {
    return this.userService.getUserData().pipe(
      tap((result) => {
        const { user } = result;

        ctx.patchState({
          user: {
            username: user.firstName + ' ' + user.lastName,
            email: user.email,
            role: UserRole.ORGANIZATION_ADMIN,
            organization: user.organization,
            purchaseIntent: user.purchaseIntent
              ? {
                  id: user.purchaseIntent.id,
                  priceId: user.purchaseIntent.priceId,
                  quantity: user.purchaseIntent.quantity,
                  freeTrial: user.purchaseIntent.freeTrial,
                }
              : null,
          },
        });
      }),
    );
  }

  private async getSepAdminUserData(ctx: StateContext<CurrentUserStateModel>) {
    const accountInformation = await this.authService.getMsalAuthenticationResult();
    const impersonationSessionData = this.sessionStorageService.getItem<SepImpersonationSessionData>(
      SepSessionStorageKeys.SEP_IMPERSONATION_SESSION_DATA,
    );

    return ctx.patchState({
      user: {
        username: accountInformation?.account.name ?? '',
        email: accountInformation?.account.username ?? '',
        role: UserRole.SEP_ADMIN,
        organization: impersonationSessionData?.organization ?? null,
        purchaseIntent: null,
      },
    });
  }
}
