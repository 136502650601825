<button
  class="text-sm font-semibold focus:outline-none"
  [ngClass]="{
    'text-cancel': color === 'red',
    'text-blue': color !== 'red',
    'cursor-wait': isLoading,
  }"
>
  <ng-content></ng-content>
</button>
