import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { LearningPlanDataService } from '../../../domains/academy/learning-plan/services/learning-plan-data.service';
import { LearningPlanEnrolledStudentTableComponent } from '../../../domains/academy/learning-plan/components/learning-plan-enrolled-student-table/learning-plan-enrolled-student-table.component';
import { LearningPlanService } from '../../../domains/academy/learning-plan/services/learning-plan.service';
import { CustomPortalError } from '../../../core/errors/models/CustomPortalError';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { AsyncPipe } from '@angular/common';
import { LearningPlanStructureComponent } from '../../../domains/academy/learning-plan/components/learning-plan-structure/learning-plan-structure.component';
import { LearningPlanInstanceDetailUiModel } from '../../../domains/academy/learning-plan/models/LearningPlanInstanceDetailUIModel';
import { toLearningPlanInstanceDetailUiModel } from '../../../domains/academy/learning-plan/mappers/dto-mappers';
import { LearningPlanStatisticsComponent } from '../../../domains/academy/learning-plan/components/learning-plan-statistics/learning-plan-statistics.component';
import { EnrollmentNotificationService } from '../../../core/services/enrollment-notification.service';
import { easeInAnimationFast } from '../../../shared/animations/ease-in-animation';

@Component({
  selector: 'app-learning-plan-detail',
  standalone: true,
  imports: [
    MatTabsModule,
    TranslocoDirective,
    MatIcon,
    RouterOutlet,
    LearningPlanEnrolledStudentTableComponent,
    AsyncPipe,
    LearningPlanStructureComponent,
    LearningPlanStatisticsComponent,
  ],
  animations: [easeInAnimationFast],
  templateUrl: './learning-plan-detail.component.html',
  styleUrl: './learning-plan-detail.component.css',
})
export class LearningPlanDetailComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();
  learningPlanInstanceDetails$: BehaviorSubject<LearningPlanInstanceDetailUiModel | null> =
    new BehaviorSubject<LearningPlanInstanceDetailUiModel | null>(null);
  selectedTabIndex = 0;
  private learningPlanId: string | undefined;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly learningPlanDataService: LearningPlanDataService,
    private readonly learningPlanService: LearningPlanService,
    private readonly snackbarService: SnackbarService,
    private readonly enrollmentNotificationService: EnrollmentNotificationService,
  ) {}

  ngOnInit(): void {
    this.observeLearningPlanInstanceIdInUrlAndFetch();
    this.enrollmentNotificationService
      .getEnrollmentChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe((enrollmentChanged) => {
        this.learningPlanId = this.learningPlanDataService.getLearningPlanInstanceId();
        if (enrollmentChanged && this.learningPlanId) {
          this.getLearningPlanDetails(this.learningPlanId);
        }
      });
  }

  private observeLearningPlanInstanceIdInUrlAndFetch(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.learningPlanInstanceDetails$.next(null);
        this.selectedTabIndex = 0;
        this.learningPlanDataService.setLearningPlanInstanceId(id);
        this.getLearningPlanDetails(id);
      }
    });
  }

  getLearningPlanDetails(id: string) {
    this.learningPlanService.getLearningPlanInstanceDetails(id).subscribe({
      next: (res) => {
        this.learningPlanDataService.setLearningPlaneName(res.learningPlanInstance.name);
        this.learningPlanInstanceDetails$.next(toLearningPlanInstanceDetailUiModel(res.learningPlanInstance));
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  showLearningPlanEnrollmentDialog() {
    void this.router.navigate(['dialog'], {
      relativeTo: this.route,
      queryParams: { step: 'enroll' },
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
