<div *transloco="let t">
  @if (showLicenseRatioCard) {
    <div
      class="rounded-xl border border-grey-lightGrey px-4 py-5 shadow-sm"
      @easeInFast
    >
      @if (type === 'basic') {
        <p class="text-sm font-semibold">
          {{ t('portal.subscription.licenses.licenseQuotaHeadline') }}
        </p>
      }
      @if (type === 'enrollment') {
        <p class="text-sm font-semibold">
          {{ enrollmentDescription }}
        </p>
      }

      <p class="mb-4 text-sm font-normal">
        {{
          t('portal.subscription.licenses.licenseQuotaDescription', {
            availableLicensesCount: (availableLicensesCount$ | async),
            totalLicensesCount: (totalLicensesCount$ | async),
          })
        }}
      </p>

      <app-sep-bar-chart [percentage]="availableToTotalLicensesRatio" />

      <div class="mt-4">
        <app-text-button (click)="addLicensesButtonClickEvent.emit()">{{
          t('portal.subscription.licenses.addLicensesButtonText')
        }}</app-text-button>
      </div>
    </div>
  } @else {
    <app-info-card
      [headline]="
        t(
          'portal.courses.enrollment.licenses.numberOfAvailableLicensesExceededHeadline',
          { numberOfAvailableLicenses: totalLicensesCount$ | async }
        )
      "
      [description]="
        t(
          'portal.courses.enrollment.licenses.numberOfAvailableLicensesExceededDescription'
        )
      "
    >
      <app-text-button (click)="addLicensesButtonClickEvent.emit()">{{
        t('portal.courses.enrollment.licenses.addLicensesButtonText')
      }}</app-text-button>
    </app-info-card>
  }
</div>
