<div>
  <ng-template #studentCard let-element>
    <div class="my-4">
      <app-user-card
        [username]="element.username"
        [email]="element.email"
        [highlightUsername]="false"
      />
    </div>
  </ng-template>
</div>

<app-sep-table
  *transloco="let t"
  [bulkActions]="bulkActions"
  [rowActions]="rowActions"
  [filterCategories]="filterCategories"
  [columns]="columns"
  [dataSource]="dataSource"
  [name]="t('portal.courses.enrollment.participantsHeadline')"
  [noDataText]="t('portal.courses.enrollment.noDataTableText')"
  [checkboxSelectedText]="t('portal.courses.enrollment.checkboxSelectedText')"
  [hasBulkActionButton]="true"
  [primaryButtonTitle]="t('portal.courses.enrollment.addParticipantsHeadline')"
  (primaryButtonClickedEvent)="showCourseEnrollmentDialog()"
  [allowExport]="false"
  (exportContentButtonClickedEvent)="exportTableContentsToCsv()"
></app-sep-table>
