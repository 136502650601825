import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogModel } from '../../../../shared/components/dialog/dialog';
import { LanguageService } from '../../../../core/services/language.service';

interface ConfirmationDialogData {
  quantity: number;
  immediatePayment: number;
  yearlyPayment: number;
  isFreeTrial: boolean;
  onConfirm: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class SubscriptionDialogService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly languageService: LanguageService,
  ) {}

  showConfirmationDialog(data: ConfirmationDialogData) {
    const upgradeConfirmationDialog = new DialogModel(this.dialog);
    upgradeConfirmationDialog.closable = true;
    upgradeConfirmationDialog.headline = this.languageService.translateOnRuntime(
      'portal.settings.billingSettings.upgradeConfirmationDialog.headline',
    );

    const descriptionKey = data.isFreeTrial
      ? 'portal.settings.billingSettings.upgradeConfirmationDialog.descriptionFreeTrial'
      : 'portal.settings.billingSettings.upgradeConfirmationDialog.description';

    upgradeConfirmationDialog.description = this.languageService.translateOnRuntime(descriptionKey, {
      immediatePayment: (data.immediatePayment / 100).toFixed(2),
      yearlyPayment: (data.yearlyPayment / 100).toFixed(2),
    });

    const submitButtonKey = data.isFreeTrial
      ? 'portal.settings.billingSettings.upgradeConfirmationDialog.submitButtonFreeTrial'
      : 'portal.settings.billingSettings.upgradeConfirmationDialog.submitButton';

    upgradeConfirmationDialog.buttonRowData = {
      confirmButtonText: this.languageService.translateOnRuntime(submitButtonKey),
      confirmButtonAction: data.onConfirm,
      declineButtonText: this.languageService.translateOnRuntime(
        'portal.settings.billingSettings.upgradeConfirmationDialog.abort',
      ),
    };

    upgradeConfirmationDialog.openDialog();

    return upgradeConfirmationDialog;
  }
}
