import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SepTableSelectionService } from './sep-table-selection.service';
import { FilterCategory } from '../../sep-filter/sep-filter.component';

@Injectable({
  providedIn: 'root',
})
export class SepTableService<T> {
  dataSource: T[] = [];
  dataView: T[] = [];
  selection: SelectionModel<T> = new SelectionModel<T>(true, []);

  private readonly dataViewSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  dataView$ = this.dataViewSubject.asObservable();

  private readonly isFilteredSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isFiltered$ = this.isFilteredSubject.asObservable();

  constructor(private readonly selectionService: SepTableSelectionService<T>) {}

  public setDataSource(dataSource: T[]) {
    this.dataSource = dataSource;
    this.dataView = dataSource;
    this.dataViewSubject.next(this.dataView);
    this.selectionService.clearSelection();
  }

  applyFilter(filterCategories: FilterCategory[]): void {
    const predicates: Array<(item: T) => boolean> = [];

    filterCategories.forEach((category) => {
      const selectedFilters = category.options.filter((option) => option.selected).map((option) => option.label);

      if (selectedFilters.length > 0) {
        predicates.push((item: T) => selectedFilters.includes(item[category.value as keyof T] as string));
      }
    });

    if (predicates.length > 0) {
      this.filterData((item: T) => predicates.every((predicate) => predicate(item)));
      this.isFilteredSubject.next(true);
    } else {
      this.filterData(() => false);
      this.isFilteredSubject.next(true);
    }
  }

  toggleAllRows() {
    this.selectionService.toggleAllRows(this.dataView);
  }

  selectRow(row: T) {
    this.selectionService.selectRow(row);
  }

  sortOrGroupData(columnKey: keyof T, ascending = true): void {
    const sampleValue = this.dataView[0]?.[columnKey];

    if (this.isObject(sampleValue)) {
      this.groupData(columnKey);
    } else {
      this.sortData(columnKey, ascending);
    }
  }

  groupData(columnKey: keyof T): void {
    const groupedData: Record<string, T[]> = {};

    this.dataView.forEach((item) => {
      let value = item[columnKey];

      if (this.isObject(value)) {
        const [firstKey] = Object.keys(value);
        // eslint-disable-next-line
        value = firstKey ? (value as Record<string, any>)[firstKey] : 'Unknown';
      }
      const key = String(value ?? 'Unknown');

      groupedData[key] ??= [];

      groupedData[key].push(item);
    });

    this.dataView = Object.values(groupedData).flat();
    this.dataViewSubject.next(this.dataView);
  }

  sortData(columnKey: keyof T, ascending = true): void {
    this.dataView = [...this.dataView].sort((a, b) => {
      const valueA = a[columnKey];
      const valueB = b[columnKey];

      const valueAStr = String(valueA || '').toLowerCase();
      const valueBStr = String(valueB || '').toLowerCase();

      if (valueAStr < valueBStr) {
        return ascending ? -1 : 1;
      }
      if (valueAStr > valueBStr) {
        return ascending ? 1 : -1;
      }
      return 0;
    });

    this.dataViewSubject.next(this.dataView);
  }

  get selection$() {
    return this.selectionService.selection$;
  }

  private filterData(predicate: (item: T) => boolean): void {
    this.dataView = this.dataSource.filter(predicate);
    this.dataViewSubject.next(this.dataView);
  }

  private isObject(value: unknown): value is Record<string, unknown> {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  }
}
