<div *transloco="let t">
  <h2 class="text-xl font-semibold">
    {{ t('portal.settings.billingSettings.headline') }}
  </h2>

  @if (billing === null) {
    <p class="text-sm font-normal text-primary">
      {{ t('portal.settings.billingSettings.noUpcomingInvoices') }}
    </p>
  } @else {
    <p class="mb-5 text-sm font-normal text-primary">
      {{
        t('portal.settings.billingSettings.description', {
          date: billing.upcomingInvoice.periodStart | date: 'dd.MM.yyyy',
        })
      }}
    </p>
    <div
      class="mt-5 w-full max-w-xl rounded-lg border border-grey-lightGrey pt-8 shadow-md"
    >
      <div class="mb-2 flex justify-between px-8 text-center">
        <p class="text-2xl font-semibold text-blue">
          {{ billing.product.type }}
        </p>
      </div>

      <div class="mt-3 px-8">
        @for (course of billing.product.courses; track course) {
          <div class="mt-1 flex items-center">
            <span
              class="mr-3 flex size-6 items-center justify-center rounded-full bg-blue-light p-1"
            >
              <mat-icon class="text-base text-blue">check_small</mat-icon>
            </span>
            <p class="text-base font-normal text-primary">
              {{ t('portal.courses.courseTitleKeys.' + course.name + '.name') }}
            </p>
          </div>
        }
      </div>

      <div
        class="mt-10 flex justify-between px-8 text-center text-xl font-semibold text-primary"
      >
        <app-increment-button
          [quantity]="quantity ?? 1"
          [minValue]="billing.upcomingInvoice.quantity"
          (quantityChange)="onQuantityChange($event)"
        ></app-increment-button>

        <p class="justify-end">
          {{
            priceInEuros(billing.upcomingInvoice.totalExcludingTaxAndDiscount)
              | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
          }}
        </p>
      </div>

      <div
        class="flex justify-between px-8 text-center text-base font-light text-grey"
      >
        <p class="">{{ t('product.licenses') }}</p>
        <p class="justify-end">
          {{ t('product.each') }}
          {{
            priceInEuros(
              billing.upcomingInvoice.unitAmountExcludingTaxAndDiscount
            ) | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
          }}
          / {{ t('product.year') }}
        </p>
      </div>

      <div
        class="mt-6 flex flex-col border-t border-t-grey-lightGrey px-8 pt-6 text-base text-grey"
      >
        @if (billing.upcomingInvoice.discountAmount > 0) {
          <div class="flex flex-row justify-between font-light">
            <span>
              {{ t('portal.settings.billingSettings.discount') }}
            </span>
            <div class="justify-end text-green-700">
              -{{
                priceInEuros(billing.upcomingInvoice.discountAmount)
                  | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
              }}
            </div>
          </div>
        }

        @if (
          billing.upcomingInvoice.taxPercentage !== null &&
          billing.upcomingInvoice.taxPercentage > 0
        ) {
          <div class="mt-1 flex flex-row justify-between font-light">
            <span
              >{{ t('product.price.tax') }} ({{
                billing.upcomingInvoice.taxPercentage
              }}%)</span
            >
            <span class="justify-end">
              {{
                priceInEuros(billing.upcomingInvoice.taxAmount)
                  | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
              }}
            </span>
          </div>
        }
        @if (billing.upcomingInvoice.total !== null) {
          <div class="mb-4 mt-1 flex flex-row justify-between font-light">
            <span>
              {{ t('product.price.total') }}
            </span>
            <span class="font-semibold">
              {{
                priceInEuros(billing.upcomingInvoice.total)
                  | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
              }}
            </span>
          </div>
        }

        <p class="mb-5 text-sm font-normal text-primary">
          {{
            t('portal.settings.billingSettings.upcomingInvoiceDescription', {
              date: billing.upcomingInvoice.periodStart | date: 'dd.MM.yyyy',
            })
          }}
        </p>
      </div>

      @if (quantity && quantity > billing.upcomingInvoice.quantity) {
        @if (newPlan && newPlan.total > billing.upcomingInvoice.quantity) {
          <div
            class="flex flex-col border-t border-t-grey-lightGrey px-8 pt-6 text-base text-grey"
          >
            <h2 class="text-base font-semibold">
              {{ t('portal.settings.billingSettings.nextYear') }}
            </h2>

            <div class="mt-1 flex flex-row justify-between font-light">
              <span>
                {{ quantity }} ×
                {{
                  priceInEuros(newPlan.unitAmountExcludingTax)
                    | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
                }}
              </span>
              <div class="justify-end">
                {{
                  priceInEuros(nextYearTotalPriceWithoutTaxAndDiscount)
                    | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
                }}
              </div>
            </div>

            @if (newPlan.discountAmount > 0) {
              <div class="mt-1 flex flex-row justify-between font-light">
                <span>
                  {{ t('portal.settings.billingSettings.discount') }}
                </span>
                <div class="justify-end text-green-700">
                  -{{
                    priceInEuros(newPlan.discountAmount)
                      | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
                  }}
                </div>
              </div>
            }

            @if (newPlan.taxPercentage !== null && newPlan.taxPercentage > 0) {
              <div class="mt-1 flex flex-row justify-between font-light">
                <span
                  >{{ t('product.price.tax') }} ({{
                    newPlan.taxPercentage
                  }}%)</span
                >
                <span class="justify-end">
                  {{
                    priceInEuros(newPlan.taxAmount)
                      | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
                  }}
                </span>
              </div>
            }

            <div class="mb-4 mt-1 flex flex-row justify-between font-light">
              <span> Total </span>
              <span class="font-semibold">
                {{
                  priceInEuros(newPlan.total)
                    | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
                }}
              </span>
            </div>
          </div>
        }

        @if (subscription$ | async; as subscription) {
          @if (priceOfUpgrade && subscription.status !== 'trialing') {
            <div
              class="flex flex-col border-t border-t-grey-lightGrey px-8 pt-6 text-base text-grey"
            >
              <h2 class="text-base font-semibold">
                {{ t('portal.settings.billingSettings.payToday') }}
              </h2>

              <div class="mt-1 flex flex-row justify-between font-light">
                <span>
                  {{ quantityOfUpgrade }} ×
                  {{
                    priceInEuros(priceOfUpgrade.unitAmountExcludingTax)
                      | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
                  }}
                </span>
                <div class="justify-end">
                  {{
                    priceInEuros(totalUpgradePriceWithoutTaxAndDiscount)
                      | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
                  }}
                </div>
              </div>

              @if (priceOfUpgrade.discountAmount > 0) {
                <div class="mt-1 flex flex-row justify-between font-light">
                  <span>
                    {{ t('portal.settings.billingSettings.discount') }}
                  </span>
                  <div class="justify-end text-green-700">
                    -{{
                      priceInEuros(priceOfUpgrade.discountAmount)
                        | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
                    }}
                  </div>
                </div>
              }

              @if (
                priceOfUpgrade.taxPercentage !== null &&
                priceOfUpgrade.taxPercentage > 0
              ) {
                <div class="mt-1 flex flex-row justify-between font-light">
                  <span
                    >{{ t('product.price.tax') }} ({{
                      priceOfUpgrade.taxPercentage
                    }}%)</span
                  >
                  <span class="justify-end">
                    {{
                      priceInEuros(priceOfUpgrade.taxAmount)
                        | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
                    }}
                  </span>
                </div>
              }

              <div class="my-4 flex justify-between text-xl text-primary">
                <span>
                  {{ t('portal.settings.billingSettings.payToday') }}
                </span>
                <span class="font-semibold">
                  {{
                    priceInEuros(priceOfUpgrade.total)
                      | currency: 'EUR' : 'symbol' : '1.2-2' : 'de-DE'
                  }}
                </span>
              </div>
            </div>
          }
        }

        <div class="mx-8 mb-4">
          <app-primary-button
            [text]="t('portal.settings.billingSettings.submitButton')"
            (buttonClicked)="showConfirmUpgradeDialog(quantity)"
            [loading]="isLoading"
          ></app-primary-button>
        </div>
      }
    </div>
  }
</div>
