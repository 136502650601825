<div class="mt-5 overflow-x-auto rounded-lg border px-6 py-5 shadow-sm">
  <div class="flex min-w-[600px] justify-between" *transloco="let t">
    <div class="min-w-48 pr-6">
      <p class="text-sm font-normal text-grey">
        {{ t('portal.learningPlans.detailPage.started') }}
      </p>
      <p class="text-sm font-semibold">
        {{ createdAt | date: 'dd.MM.yyyy' }}
      </p>
    </div>
    <div class="min-w-48 border-l-2 px-6">
      <p class="text-sm font-normal text-grey">
        {{ t('portal.learningPlans.detailPage.participants') }}
      </p>
      <p class="text-sm font-semibold">
        {{ numberOfEnrolledStudents }}
      </p>
    </div>
    <div class="min-w-48 border-l-2 px-6">
      <p class="text-sm font-normal text-grey">
        {{ t('portal.learningPlans.detailPage.inProgress') }}
      </p>
      <p class="text-sm font-semibold">
        {{ numberOfEnrolledStudentsLearningPlanStarted }}
        @if (
          numberOfEnrolledStudentsLearningPlanStarted /
            numberOfEnrolledStudents !==
            0 && numberOfEnrolledStudents !== 0
        ) {
          ({{
            numberOfEnrolledStudentsLearningPlanStarted /
              numberOfEnrolledStudents | percent
          }})
        }
      </p>
    </div>
    <div class="min-w-48 border-l-2 pl-6">
      <p class="text-sm font-normal text-grey">
        {{ t('portal.learningPlans.detailPage.completed') }}
      </p>
      <p class="text-sm font-semibold">
        {{ numberOfCompletedStudents || 0 }}
        @if (
          numberOfCompletedStudents / numberOfEnrolledStudents !== 0 &&
          numberOfEnrolledStudents !== 0
        ) {
          ({{ numberOfCompletedStudents / numberOfEnrolledStudents | percent }})
        }
      </p>
    </div>
  </div>
</div>
