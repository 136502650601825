import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { SepBarChartComponent } from '../../../../../shared/components/sep-bar-chart/sep-bar-chart.component';
import { TranslocoModule } from '@jsverse/transloco';
import { LicenseCalculationsService } from '../../services/license-calculations.service';
import { easeInAnimationFast } from '../../../../../shared/animations/ease-in-animation';
import { EnrollmentNotificationService } from '../../../../../core/services/enrollment-notification.service';
import { TextButtonComponent } from '../../../../../shared/components/text-button/text-button.component';
import { InfoCardComponent } from '../../../../../shared/components/info-card/info-card.component';

@Component({
  selector: 'app-license-info-card',
  standalone: true,
  imports: [AsyncPipe, SepBarChartComponent, TranslocoModule, TextButtonComponent, InfoCardComponent],
  templateUrl: './license-info-card.component.html',
  styleUrl: './license-info-card.component.css',
  animations: [easeInAnimationFast],
})
export class LicenseInfoCardComponent implements OnInit {
  @Input() type: 'basic' | 'enrollment' = 'basic';
  @Input() enrollmentDescription?: string;
  @Input() showLicenseRatioCard: boolean;
  @Output() addLicensesButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();
  availableLicensesCount$: Observable<number>;
  totalLicensesCount$: Observable<number>;
  availableToTotalLicensesRatio$: Observable<number>;
  availableToTotalLicensesRatio: number;
  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly licenseCalculationsService: LicenseCalculationsService,
    private readonly enrollmentNotificationService: EnrollmentNotificationService,
  ) {}

  ngOnInit(): void {
    this.retrieveLicenseInformation();
    this.observeEnrollments();
    this.observeAvailableToTotalLicensesRatio();
  }

  retrieveLicenseInformation() {
    this.availableLicensesCount$ = this.licenseCalculationsService.getAvailableLicensesCount();
    this.totalLicensesCount$ = this.licenseCalculationsService.getTotalLicensesCount();
    this.availableToTotalLicensesRatio$ = this.licenseCalculationsService.getAvailableToTotalLicensesRatio();
  }

  private observeEnrollments() {
    this.enrollmentNotificationService
      .getEnrollmentChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe((enrollmentChanged) => {
        if (enrollmentChanged) {
          this.licenseCalculationsService.refreshLicenses();
        }
      });
  }

  private observeAvailableToTotalLicensesRatio() {
    this.availableToTotalLicensesRatio$.pipe(takeUntil(this.destroy$)).subscribe((ratio) => {
      this.availableToTotalLicensesRatio = ratio;
    });
  }
}
