import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PrimaryButtonComponent } from '../primary-button/primary-button.component';

export interface FilterOption {
  label: string;
  selected: boolean;
}

export interface FilterCategory {
  label: string;
  value: string;
  options: FilterOption[];
}

@Component({
  selector: 'app-sep-filter',
  standalone: true,
  imports: [FormsModule, CommonModule, MatCheckboxModule, PrimaryButtonComponent],
  templateUrl: './sep-filter.component.html',
  styleUrl: './sep-filter.component.css',
})
export class SepFilterComponent implements OnInit {
  @Input({ required: true }) categories: FilterCategory[] = [];
  @Input({ required: true }) applyButtonText: string;
  @Output() filtersChanged: EventEmitter<FilterCategory[]> = new EventEmitter<FilterCategory[]>();
  openCategories: Set<string> = new Set();

  ngOnInit() {
    this.openCategories = new Set<string>(this.categories.map((c) => c.label));
  }

  toggleCategory(label: string): void {
    if (this.openCategories.has(label)) {
      this.openCategories.delete(label);
    } else {
      this.openCategories.add(label);
    }
  }

  isOpen(label: string): boolean {
    return this.openCategories.has(label);
  }

  applyFilters(): void {
    this.filtersChanged.emit(this.categories);
  }

  toggleOptionSelection(option: FilterOption) {
    option.selected = !option.selected;
  }
}
