import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { SepTableComponent, SepTableType } from '../../../../../shared/components/sep-table/sep-table.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BadgeComponent } from '../../../../../shared/components/badge/badge.component';
import { UserCardComponent } from '../../../../../shared/components/user-card/user-card.component';
import { EnrollmentTableBaseComponent } from '../../../base/EnrollmentTableBaseComponent';
import { StudentEligibleForEnrollmentBaseUIModel } from '../../../base/EnrolledStudentBaseUIModel';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-learning-plan-enrollment-table',
  standalone: true,
  imports: [
    CommonModule,
    UserCardComponent,
    SepTableComponent,
    TranslocoDirective,
    BadgeComponent,
    MatIconModule,
    MatTooltipModule,
  ],
  templateUrl: './learning-plan-enrollment-table.component.html',
})
export class LearningPlanEnrollmentTableComponent extends EnrollmentTableBaseComponent {
  @Input({ required: true }) students!: StudentEligibleForEnrollmentBaseUIModel[];
  @Input() selectedStudents: StudentEligibleForEnrollmentBaseUIModel[];
  @Output() primaryButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('personCard', { static: true }) personCard!: TemplateRef<{ $implicit: string }>;
  sepTableType = SepTableType;

  protected configureColumns(): void {
    this.columns[0].renderComponent = this.personCard;
  }

  isStudentSelected(studentId: string): boolean {
    return this.selectedStudents.some((s) => s.id === studentId);
  }
}
