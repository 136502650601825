import { Component } from '@angular/core';
import { CourseInstanceInfoCardComponent } from '../course-instance-info-card/course-instance-info-card.component';
import { AsyncPipe } from '@angular/common';
import { easeInAnimationFast } from '../../../../../shared/animations/ease-in-animation';
import { CourseInstanceOverviewBaseComponent } from '../../../base/CourseInstanceOverviewBaseComponent';

@Component({
  selector: 'app-course-instances-overview',
  standalone: true,
  imports: [AsyncPipe, CourseInstanceInfoCardComponent],
  animations: [easeInAnimationFast],
  templateUrl: './course-instances-overview.component.html',
  styleUrl: './course-instances-overview.component.css',
})
export class CourseInstancesOverviewComponent extends CourseInstanceOverviewBaseComponent {}
