<div class="mx-8 mt-12" *transloco="let t">
  <h1 class="mb-8 text-3xl font-semibold">
    {{ t('portal.settings.settingsHeadline') }}
  </h1>
  <mat-tab-group
    [selectedIndex]="selectedTabIndex"
    (selectedIndexChange)="onTabChange($event)"
    class="remove-border-bottom"
  >
    @for (tab of tabs; track tab) {
      <mat-tab [label]="t('portal.settings.tabs.' + tab.key)">
        <div class="mt-5 mb-2 {{ tab?.wrapperClass }}">
          <ng-container [ngComponentOutlet]="tab.component" />
        </div>
      </mat-tab>
    }
  </mat-tab-group>
  <router-outlet />
</div>
