<div class="container w-full rounded-lg border" *transloco="let t">
  <div class="relative min-h-[260px] overflow-x-auto" #scrollableContainer>
    <div class="flex w-full">
      @for (phase of phases; track phase) {
        <div class="flex flex-1 flex-col">
          <div
            class="w-full bg-grey-lightGrey p-3 text-center text-sm font-medium text-grey"
            [ngClass]="{
              'border-r border-grey-midGrey': $index < phases.length - 1,
            }"
          >
            {{
              t(
                'portal.learningPlans.enrolledStudentTable.currentPhase.' +
                  phase.name
              )
            }}
          </div>
          <!-- Course Instance Cards -->
          <div class="flex flex-row justify-center gap-4 p-4 pb-10">
            @for (
              courseInstance of phase.courseInstances;
              track courseInstance
            ) {
              <div
                class="box flex min-w-[350px] flex-col rounded-lg border border-gray-300 p-4 shadow-md"
                #courseInstanceElement
              >
                <!-- Header section -->
                <div class="mt-auto flex justify-between">
                  <div class="flex items-center">
                    <mat-icon class="text-md mr-2 text-orange"
                      >description</mat-icon
                    >
                    <h3 class="text-sm font-semibold text-primary">
                      {{
                        t(
                          'portal.courses.courseTitleKeys.' +
                            courseInstance.name +
                            '.name'
                        )
                      }}
                    </h3>
                  </div>
                  <div class="flex items-center">
                    <mat-icon class="items-center text-base text-grey"
                      >person</mat-icon
                    >
                    <span class="text-xs font-semibold">
                      {{ courseInstance.numberOfEnrolledStudents }}
                    </span>
                  </div>
                </div>

                <p class="min-h-20 py-2 text-sm text-grey">
                  {{
                    t(
                      'portal.courses.courseTitleKeys.' +
                        courseInstance.name +
                        '.description'
                    )
                  }}
                </p>

                <!-- Footer section -->
                <div class="mt-auto flex items-center justify-between">
                  @if (false) {
                    <div class="flex items-center space-x-1 text-sm">
                      <app-link [description]="'Details'"></app-link>
                      <mat-icon class="text-base font-semibold text-blue"
                        >open_in_new</mat-icon
                      >
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
</div>
