<div
  class="shadow-xs max-w-md overflow-hidden rounded-lg border border-grey-lightGrey px-6 py-5"
  *transloco="let t"
>
  <div class="max-w-60">
    <div class="mb-2 flex items-center justify-between">
      <h2 class="text-base font-semibold">
        {{ t('portal.subscription.licenses.totalLicensesText') }}:
      </h2>
      <span class="text-base font-bold text-grey">{{
        totalLicensesCount$ | async
      }}</span>
    </div>
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <mat-icon class="p-1 text-xs text-orange-500">circle</mat-icon>
        <span class="ml-2 text-base font-normal text-grey"
          >{{ t('portal.subscription.licenses.availableLicensesText') }}:</span
        >
      </div>
      <span class="text-base font-bold text-grey">{{
        availableLicensesCount$ | async
      }}</span>
    </div>
    <div class="mr-[-29px] mt-3 flex items-center justify-between">
      <div class="flex items-center">
        <mat-icon class="p-1 text-xs text-grey-lightGrey">circle</mat-icon>
        <span class="ml-2 text-base font-normal text-grey"
          >{{ t('portal.subscription.licenses.assignedLicensesText') }}:</span
        >
      </div>
      <span class="flex items-center text-base font-bold text-grey">
        {{ assignedLicensesCount$ | async }}
        <mat-icon
          class="material-icons-outlined ml-1 cursor-pointer text-base text-grey-lightGrey"
          [matTooltip]="
            t('portal.subscription.licenses.assignedLicensesTooltipText', {
              assignedLicenses: assignedLicensesCount$ | async,
              reservedLicenses: reservedLicensesCount$ | async,
            })
          "
          matTooltipPosition="above"
          matTooltipClass="tooltip-above"
          >help_outline</mat-icon
        >
      </span>
    </div>
  </div>
  <div class="pt-4">
    @if (
      availableToTotalLicensesRatio$ | async;
      as availableToTotalLicensesRatio
    ) {
      <app-sep-bar-chart
        [percentage]="availableToTotalLicensesRatio"
      ></app-sep-bar-chart>
    }
  </div>
</div>
