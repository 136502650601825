import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SepTableComponent } from '../../../../../shared/components/sep-table/sep-table.component';
import { TableBulkAction, TableRowAction } from '../../../../../shared/components/sep-table/Action';
import { ActivatedRoute, Router } from '@angular/router';
import { UserCardComponent } from '../../../../../shared/components/user-card/user-card.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { LanguageService } from '../../../../../core/services/language.service';
import { Column } from '../../../../../shared/components/sep-table/Column';
import { MatDialog } from '@angular/material/dialog';
import { EnrollmentNotificationService } from '../../../../../core/services/enrollment-notification.service';
import { CourseService } from '../../services/course.service';
import { CourseDataService } from '../../services/course-data.service';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { CustomPortalError } from '../../../../../core/errors/models/CustomPortalError';
import { AnalyticsService } from '../../../../../core/services/analytics/analytics.service';
import { FilterCategory } from '../../../../../shared/components/sep-filter/sep-filter.component';
import { CourseEnrolledStudentUIModel } from '../../models/CourseEnrolledStudentUIModel';
import { EnrolledStudentTableBaseComponent } from '../../../base/EnrolledStudentTableBaseComponent';

@Component({
  selector: 'app-learning-plan-enrolled-student-table',
  standalone: true,
  imports: [SepTableComponent, UserCardComponent, TranslocoDirective],
  templateUrl: './enrolled-student-table.component.html',
  styleUrl: './enrolled-student-table.component.css',
})
export class EnrolledStudentTableComponent
  extends EnrolledStudentTableBaseComponent
  implements AfterViewInit, OnChanges
{
  @ViewChild('studentCard', { static: true }) studentCard!: TemplateRef<{ $implicit: string }>;
  @Input({ required: true }) students: CourseEnrolledStudentUIModel[];
  dataSource: CourseEnrolledStudentUIModel[] = [];

  columns: Array<Column<CourseEnrolledStudentUIModel, string | { $implicit: string }>> = [
    {
      headerName: this.getTranslation('users.table.header.nameAndEmail'),
      renderComponent: undefined,
      key: 'username',
    },
    {
      headerName: this.getTranslation('users.table.header.status'),
      field: 'courseProgress',
      key: 'courseProgress',
    },
  ];

  bulkActions: Array<TableBulkAction<CourseEnrolledStudentUIModel>> = [
    {
      label: this.getTranslation('courses.unenrollment.bulkUnenrollButtonText'),
      icon: 'group_remove',
      executeAction: (targetedStudents: CourseEnrolledStudentUIModel[]) => {
        this.showUnenrollmentConfirmationDialog(targetedStudents, () => {
          this.unenrollStudents(targetedStudents);
        });
      },
    },
    {
      label: this.getTranslation('courses.reminders.bulkSendReminder'),
      icon: 'send',
      executeAction: (targetedStudents: CourseEnrolledStudentUIModel[]) => {
        this.showSendReminderConfirmationDialog(targetedStudents, () => {
          this.sendReminder(targetedStudents);
        });
      },
    },
  ];

  rowActions: Array<TableRowAction<CourseEnrolledStudentUIModel>> = [
    {
      label: this.getTranslation('courses.unenrollment.unenrollButtonText'),
      icon: 'person_remove',
      executeAction: (targetedStudent: CourseEnrolledStudentUIModel) => {
        this.showUnenrollmentConfirmationDialog([targetedStudent], () => {
          this.unenrollStudents([targetedStudent]);
        });
      },
    },
    {
      label: this.getTranslation('courses.reminders.sendReminder'),
      icon: 'send',
      executeAction: (targetedStudent: CourseEnrolledStudentUIModel) => {
        this.showSendReminderConfirmationDialog([targetedStudent], () => {
          this.sendReminder([targetedStudent]);
        });
      },
    },
  ];

  filterCategories: FilterCategory[] | undefined;

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    languageService: LanguageService,
    analyticsService: AnalyticsService,
    dialog: MatDialog,
    private readonly enrollmentNotificationService: EnrollmentNotificationService,
    private readonly courseService: CourseService,
    private readonly courseDataService: CourseDataService,
    private readonly snackbarService: SnackbarService,
  ) {
    super(dialog, languageService, analyticsService, router, activatedRoute);
  }

  ngAfterViewInit(): void {
    this.columns[0].renderComponent = this.studentCard;
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['students'].currentValue !== changes['students'].previousValue) {
      this.dataSource = this.translateProperties(changes['students'].currentValue as CourseEnrolledStudentUIModel[]);
      this.initializeCourseProgressFilter();
    }
  }

  private unenrollStudents(students: CourseEnrolledStudentUIModel[]) {
    const courseId = this.courseDataService.getCourseId();
    if (!courseId) {
      console.error('No given course Id');
      return;
    }
    this.courseService.unenrollStudentsFromCourse(students, courseId).subscribe({
      next: () => {
        this.analyticsService.track('students_unenrolled', {
          courseId: courseId,
          students: students.map(({ id }) => ({ id })),
        });
        this.enrollmentNotificationService.setEnrollmentChanged();
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  translateProperties(students: CourseEnrolledStudentUIModel[]): CourseEnrolledStudentUIModel[] {
    return students.map((student) => ({
      ...student,
      courseProgress: this.getTranslation('courses.analytics.courseProgress.' + student.courseProgress),
    }));
  }

  private initializeCourseProgressFilter(): void {
    const uniqueCourseProgressValues = Array.from(new Set(this.students.map((student) => student.courseProgress)));

    this.filterCategories = [
      {
        label: this.getTranslation('users.table.header.status'),
        value: 'courseProgress',
        options: uniqueCourseProgressValues.map((value) => ({
          label: this.getTranslation('courses.analytics.courseProgress.' + value),
          selected: true,
        })),
      },
    ];
  }

  private sendReminder(students: CourseEnrolledStudentUIModel[]) {
    const courseId = this.courseDataService.getCourseId();
    if (!courseId) {
      console.error('No given course Id');
      return;
    }
    this.courseService.sendReminderToStudentsFromCourse(students, courseId).subscribe({
      next: () => {
        this.analyticsService.track('course_reminder_sent', {
          courseId: courseId,
          students: students.map(({ id }) => ({ id })),
        });
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  exportTableContentsToCsv() {
    const courseId = this.courseDataService.getCourseId();
    if (!courseId) {
      console.error('No given course Id');
      return;
    }
    this.courseService.exportStudentInfoToCsv(courseId).subscribe((data) => {
      this.openFile(data);
    });
  }
}
