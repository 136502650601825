import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { PortalApiRepository } from '../../../../core/repositories/portal-api-repository';
import { CurrentUserState } from '../../../../core/store/current-user/current-user.state';
import {
  LearningPlanInstancesResponseDTO,
  LearningPlanInstanceEnrollmentsResponseDTO,
  SendReminderRequestDTO,
  LearningPlanEnrollmentRequestDTO,
  LearningPlanUnenrollmentRequestDTO,
  LearningPlanInstanceDetailResponseDTO,
} from '../models/LearningPlanDTO';
import {
  toLearningPlanEnrollmentRequestDTO,
  toLearningPlanUnenrollmentRequestDTO,
  toSendReminderRequestDTO,
} from '../mappers/dto-mappers';
import { LearningPlanEnrolledStudentUIModel } from '../models/LearningPlanEnrolledStudentUIModel';

@Injectable({
  providedIn: 'root',
})
export class LearningPlanService extends PortalApiRepository {
  constructor() {
    super();
  }

  getLearningPlanInstances(): Observable<LearningPlanInstancesResponseDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    if (!subscriptionId) {
      return EMPTY;
    }

    const path = `/subscriptions/${subscriptionId}/LearningPlanInstances`;

    return this.get<LearningPlanInstancesResponseDTO>(path);
  }

  getLearningPlanInstanceDetails(id: string): Observable<LearningPlanInstanceDetailResponseDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    const path = `/subscriptions/${subscriptionId}/LearningPlanInstances/${id}`;

    return this.get<LearningPlanInstanceDetailResponseDTO>(path);
  }

  getLearningPlanEnrollments(id: string): Observable<LearningPlanInstanceEnrollmentsResponseDTO> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    const path = `/subscriptions/${subscriptionId}/LearningPlanInstances/${id}/enrollments`;

    return this.get<LearningPlanInstanceEnrollmentsResponseDTO>(path);
  }

  enrollStudentsToLearningPlan(students: Array<{ id: string }>, learningPlanInstanceId: string): Observable<void> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    const path = `/subscriptions/${subscriptionId}/LearningPlanInstances/${learningPlanInstanceId}/enroll`;
    const payload = toLearningPlanEnrollmentRequestDTO(students);

    return this.post<LearningPlanEnrollmentRequestDTO, void>(path, payload);
  }

  unenrollStudentsFromLearningPlan(
    students: LearningPlanEnrolledStudentUIModel[],
    learningPlanInstanceId: string,
  ): Observable<void> {
    const subscriptionId = this.store.selectSnapshot(CurrentUserState.subscriptionId);
    const path = `/subscriptions/${subscriptionId}/LearningPlanInstances/${learningPlanInstanceId}/unenroll`;
    const payload = toLearningPlanUnenrollmentRequestDTO(students);

    return this.post<LearningPlanUnenrollmentRequestDTO, void>(path, payload);
  }

  sendReminder(students: LearningPlanEnrolledStudentUIModel[]): Observable<void> {
    const path = `/send-reminder/manual`;
    const payload = toSendReminderRequestDTO(students);

    return this.post<SendReminderRequestDTO, void>(path, payload);
  }
}
