import { Component, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionService } from '../../services/subscription.service';
import { Observable, Subject, takeUntil, take } from 'rxjs';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { PrimaryButtonComponent } from '../../../../../shared/components/primary-button/primary-button.component';
import { ButtonType } from '../../../../../shared/components/primary-button/ButtonType';
import { IncrementButtonComponent } from '../../../../../shared/components/increment-button/increment-button.component';
import { DialogModel } from '../../../../../shared/components/dialog/dialog';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { CustomPortalError } from '../../../../../core/errors/models/CustomPortalError';
import { SubscriptionDialogService } from '../../services/subscription-dialog.service';
import { LicenseCalculationsService } from '../../services/license-calculations.service';
import { CurrentUserState } from '../../../../../core/store/current-user/current-user.state';
import { Select } from '@ngxs/store';
import { Subscription } from '../../../../../core/store/current-user/models/CurrentUserUIModel';
import { InvoicePreviewDTO, SubscriptionBillingResponseDTO } from '../../models/SubscriptionDTO';
import { AnalyticsService } from '../../../../../core/services/analytics/analytics.service';

@Component({
  selector: 'app-subscription-settings',
  standalone: true,
  imports: [
    TranslocoDirective,
    MatIcon,
    CurrencyPipe,
    PrimaryButtonComponent,
    IncrementButtonComponent,
    AsyncPipe,
    DatePipe,
  ],
  templateUrl: './subscription-settings.component.html',
})
export class SubscriptionSettingsComponent implements OnInit, OnDestroy {
  @Select(CurrentUserState.subscription) subscription$: Observable<Subscription | null>;
  buttonType = ButtonType;
  billing: SubscriptionBillingResponseDTO | null = null;
  priceOfUpgrade: InvoicePreviewDTO | null = null;
  newPlan: InvoicePreviewDTO | null = null;
  quantity: number | undefined;
  quantityOfUpgrade = 0;
  isLoading = false;
  confirmUpgradeDialog: DialogModel | null = null;

  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly subscriptionService: SubscriptionService,
    private readonly licenseCalculationService: LicenseCalculationsService,
    private readonly snackBarService: SnackbarService,
    private readonly subscriptionDialogService: SubscriptionDialogService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.getBillingInformation();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onQuantityChange(newQuantity: number) {
    this.quantity = newQuantity;
    this.getUpgradePriceInformation(newQuantity);
    this.isLoading = true;
  }

  priceInEuros(cents: number): number {
    return cents / 100;
  }

  showConfirmUpgradeDialog(quantity: number) {
    if (!this.priceOfUpgrade || !this.newPlan) {
      return;
    }

    this.subscription$.pipe(take(1)).subscribe((subscription) => {
      if (this.priceOfUpgrade && this.newPlan) {
        this.confirmUpgradeDialog = this.subscriptionDialogService.showConfirmationDialog({
          quantity,
          immediatePayment: this.priceOfUpgrade.total,
          yearlyPayment: this.newPlan.total,
          isFreeTrial: subscription?.status === 'trialing',
          onConfirm: () => {
            this.upgradeSubscription(quantity);
          },
        });
      }
    });
  }

  private upgradeSubscription(quantity: number) {
    if (this.confirmUpgradeDialog?.buttonRowData) {
      this.confirmUpgradeDialog.buttonRowData.confirmButtonLoading = true;
      this.confirmUpgradeDialog.buttonRowData.confirmButtonDisabled = true;
    }

    this.subscriptionService
      .upgradeSubscription(quantity)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.getBillingInformation();
          this.confirmUpgradeDialog?.closeDialog();
          this.licenseCalculationService.refreshLicenses();
          this.analyticsService.track('license_quantity_updated', { newQuantity: quantity });
        },
        error: (error: CustomPortalError) => {
          this.snackBarService.error(error.errorType);
        },
      });
  }

  private getBillingInformation() {
    this.subscriptionService
      .getBillingInformation()
      .pipe(takeUntil(this.destroy$))
      .subscribe((billingDTO) => {
        this.billing = billingDTO;
        this.quantity = billingDTO.upcomingInvoice.quantity;
      });
  }

  private getUpgradePriceInformation(quantity: number) {
    this.subscriptionService
      .getUpgradePriceInformation(quantity)
      .pipe(takeUntil(this.destroy$))
      .subscribe((upgradePreviewResponseDTO) => {
        this.priceOfUpgrade = upgradePreviewResponseDTO.priceOfUpgrade;
        this.newPlan = upgradePreviewResponseDTO.newPlan;
        this.quantityOfUpgrade = this.billing ? quantity - this.billing.upcomingInvoice.quantity : 0;
        this.isLoading = false;
      });
  }

  get nextYearTotalPriceWithoutTaxAndDiscount(): number {
    if (!this.newPlan) {
      return 0;
    }

    return this.newPlan.unitAmountExcludingTax * (this.quantity ?? 0);
  }

  get totalUpgradePriceWithoutTaxAndDiscount(): number {
    if (!this.priceOfUpgrade) {
      return 0;
    }

    return this.priceOfUpgrade.unitAmountExcludingTax * this.quantityOfUpgrade;
  }
}
