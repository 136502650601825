<div class="ml-8 mt-20">
  @if (courseDetails$ | async; as courseDetails) {
    <div @easeInFast>
      <h1 class="mb-8 text-3xl font-semibold">
        {{
          translocoCourseDetailKeyPrefix + courseDetails?.course?.name + '.name'
            | transloco
        }}
      </h1>
      <div class="mb-5 w-1/3">
        <app-course-statistics
          [students]="courseDetails.students"
        ></app-course-statistics>
      </div>
      <div class="w-4/5">
        <app-learning-plan-enrolled-student-table
          [students]="courseDetails.students"
        ></app-learning-plan-enrolled-student-table>
      </div>
    </div>
  }
  <router-outlet></router-outlet>
</div>
