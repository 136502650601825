<div
  class="rounded-lg border border-orange-light bg-orange-veryLight px-6 py-4"
>
  <div class="flex items-start">
    <div
      class="flex size-10 items-center justify-center rounded-3xl bg-orange-100 p-2 text-grey"
    >
      <mat-icon class="material-icons-outlined text-orange"
        >info_circle</mat-icon
      >
    </div>
    <div class="ml-2">
      <h2 class="text-sm font-semibold text-primary">{{ headline }}</h2>
      <p class="mb-2 mt-1 text-sm font-normal text-grey">{{ description }}</p>
      <ng-content />
    </div>
  </div>
</div>
