<ng-template #status let-element>
  <div
    class="flex max-w-28 flex-row flex-wrap items-center text-xs"
    *transloco="let t"
  >
    @if (element.validation.isValid) {
      <mat-icon class="material-icons-outlined text-sm text-grey-midGrey"
        >check_circle</mat-icon
      >
    } @else {
      @for (error of element.validation.errors; track error) {
        <span class="mr-1 text-[10px] text-cancel">{{
          t('portal.users.creation.csvUpload.errors.' + error)
        }}</span>
      }
    }
  </div>
</ng-template>

<div *transloco="let t" class="flex h-full flex-col">
  <header class="mt-5 pl-6">
    <p class="mb-8 text-lg font-semibold">
      {{ t('portal.users.creation.csvUploadText') }}
    </p>
  </header>
  <div class="p-12">
    @if (!parsedUsers) {
      <div class="flex flex-wrap items-center justify-center px-10 pb-8 pt-6">
        <div
          class="relative flex h-48 w-full flex-col items-center justify-center rounded-lg border border-grey-midGrey p-2"
          appDragOver
        >
          <input
            #fileInput
            type="file"
            class="absolute inset-0 size-full cursor-pointer opacity-0"
            (change)="onFileSelected($event)"
            accept=".csv"
          />
          <div
            class="flex size-10 items-center justify-center rounded-3xl bg-background-grey p-2 text-grey"
          >
            <mat-icon class="material-icons-outlined"> cloud_upload</mat-icon>
          </div>
          <div>
            <app-text-button (click)="fileInput.click()">{{
              t('portal.users.creation.csvUpload.buttonText')
            }}</app-text-button>
            <span class="text-sm">
              {{ t('portal.users.creation.csvUpload.description') }}</span
            >
          </div>
        </div>
        <div class="mt-2 text-xs">
          <!--Language specifc csv download should be tackled in a follow-up ticket-->
          {{ t('portal.users.creation.csvUpload.exampleFileDescription')
          }}<app-link
            [description]="t('portal.users.creation.csvUpload.csvTemplate')"
            href="assets/csv/muster.csv"
          ></app-link>
        </div>
      </div>
    }
    @if (parsedUsers) {
      <div class="w-full rounded-lg border border-blue px-4 py-2">
        <div class="flex items-center">
          <div
            class="m-2 flex size-8 items-center justify-center rounded-3xl bg-blue-veryLight p-2 text-blue"
          >
            <mat-icon class="material-icons-outlined w-4 text-base">
              upload_file
            </mat-icon>
          </div>
          <p class="m-0 text-sm font-medium">{{ fileName }}</p>
          <app-text-button
            class="m-0 ml-auto pr-1"
            [color]="buttonColor.RED"
            (click)="clearData()"
            >{{ t('portal.users.creation.csvUpload.remove') }}</app-text-button
          >
        </div>
        <app-simple-table
          [columns]="columns"
          [dataSource]="parsedUsers"
        ></app-simple-table>
      </div>
    }

    @if (invalidUserCount > 0) {
      <div
        class="mt-2 flex w-full items-center rounded-lg border border-status-warning bg-status-danger px-4 py-1"
        @easeIn
      >
        <mat-icon class="material-icons-outlined text-base text-cancel">
          priority_high
        </mat-icon>
        <span class="ml-1 text-xs text-grey">
          {{
            t('portal.users.creation.csvUpload.errorHint', {
              count: invalidUserCount,
            })
          }}</span
        >
      </div>
    }

    @if (isLoading) {
      <div
        class="mt-2 flex w-full items-center rounded-lg border border-orange bg-orange-veryLight px-4 py-1"
        @easeIn
      >
        <mat-icon class="material-icons-outlined text-sm text-orange">
          hourglass_empty
        </mat-icon>
        <span class="ml-1 text-xs text-grey">{{
          t('portal.users.creation.csvUpload.loadingHint')
        }}</span>
      </div>
    }
  </div>

  <footer class="w-full border-t-2 border-grey-lightGrey px-4 py-1">
    <app-button-row
      [primaryButtonText]="t('portal.users.creation.create')"
      [disablePrimaryButton]="
        !parsedUsers || parsedUsers.length < 1 || invalidUserCount > 0
      "
      (primaryButtonClickedEvent)="createUsers()"
      [isLoading]="isLoading"
      [secondaryButtonText]="t('general.back')"
      (secondaryButtonClickedEvent)="handleBackButtonClick()"
    ></app-button-row>
  </footer>
</div>
