@if (learningPlanInstanceDetails$ | async; as learningPlanInstanceDetails) {
  <div class="mx-8 mt-20" *transloco="let t" @easeInFast>
    <div class="flex items-center justify-between">
      <h1 class="mb-8 text-3xl font-semibold">
        {{
          t(
            'portal.learningPlans.learningPlanNameKeys.' +
              learningPlanInstanceDetails.name
          )
        }}
      </h1>
      <button
        mat-icon-button
        class="flex items-center rounded-md bg-blue p-2 hover:bg-blue-hover"
        (click)="showLearningPlanEnrollmentDialog()"
      >
        <mat-icon class="text-base text-white">add</mat-icon>
        <mat-icon class="text-white">person</mat-icon>
      </button>
    </div>
    <mat-tab-group
      class="remove-border-bottom"
      [(selectedIndex)]="selectedTabIndex"
    >
      <mat-tab [label]="t('portal.learningPlans.detailPage.tabs.overview')">
        <div class="mt-10 w-11/12">
          <app-learning-plan-statistics
            [createdAt]="learningPlanInstanceDetails.createdAt"
            [numberOfEnrolledStudents]="
              learningPlanInstanceDetails.numberOfEnrolledStudents
            "
            [numberOfCompletedStudents]="
              learningPlanInstanceDetails.numberOfCompletedStudents
            "
            [numberOfEnrolledStudentsLearningPlanStarted]="
              learningPlanInstanceDetails.numberOfEnrolledStudentsLearningPlanStarted
            "
          />
        </div>
        <div class="mt-10 w-full">
          <app-learning-plan-structure
            [phases]="learningPlanInstanceDetails.orderedPhases || []"
          ></app-learning-plan-structure>
        </div>
      </mat-tab>

      <mat-tab [label]="t('portal.learningPlans.detailPage.tabs.participants')">
        <div class="mt-5">
          <app-learning-plan-enrolled-student-table
            [tabIndex]="selectedTabIndex"
          />
        </div>
      </mat-tab>
    </mat-tab-group>
    <!--router outlet for route aware dialog-->
    <router-outlet></router-outlet>
  </div>
}
