<div class="ml-8 mt-20" @easeInFast *transloco="let t">
  <h1 class="text-3xl font-semibold">
    {{ t('portal.courses.overview.headline') }}
  </h1>
  @if (subscription$ | async; as subscription) {
    @if (subscription.isBoundToLearningPlans) {
      <app-learning-plan-course-instance-overview />
    } @else {
      <app-course-instances-overview />
    }
  }
</div>
