import { Component } from '@angular/core';
import { CourseInstanceOverviewBaseComponent } from '../../../base/CourseInstanceOverviewBaseComponent';
import { AsyncPipe } from '@angular/common';
import { CourseInstancesOverviewComponent } from '../course-instances-overview/course-instances-overview.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { CourseInstanceInfoCardComponent } from '../course-instance-info-card/course-instance-info-card.component';

@Component({
  selector: 'app-learning-plan-course-instance-overview',
  standalone: true,
  imports: [AsyncPipe, CourseInstancesOverviewComponent, TranslocoDirective, CourseInstanceInfoCardComponent],
  templateUrl: './learning-plan-course-instance-overview.component.html',
})
export class LearningPlanCourseInstanceOverviewComponent extends CourseInstanceOverviewBaseComponent {}
