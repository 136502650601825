import { Injectable } from '@angular/core';
import * as d3 from 'd3';

@Injectable({
  providedIn: 'root',
})
export class LineDrawingService {
  constructor() {}

  createSvgContainer(
    container: HTMLElement,
    width: number,
    height: number,
  ): d3.Selection<SVGSVGElement, unknown, null, undefined> {
    return d3
      .select(container)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .style('position', 'absolute')
      .style('top', '0')
      .style('left', '0')
      .style('pointer-events', 'none');
  }

  createArrowMarker(svg: d3.Selection<SVGSVGElement, unknown, null, undefined>, id: string, orient = 'auto'): void {
    const marker = svg
      .append('defs')
      .append('marker')
      .attr('id', id)
      .attr('viewBox', '0 0 10 10')
      .attr('refX', 10)
      .attr('refY', 5)
      .attr('markerWidth', 5)
      .attr('markerHeight', 5)
      .attr('orient', orient);

    marker.append('path').attr('d', 'M0,0 L10,5 L0,10').attr('fill', 'grey');
  }

  createLine(
    svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
    startX: number,
    startY: number,
    endX: number,
    endY: number,
    markerId = '',
  ): d3.Selection<SVGLineElement, unknown, null, undefined> {
    const line = svg
      .append('line')
      .attr('x1', startX)
      .attr('y1', startY)
      .attr('x2', endX)
      .attr('y2', endY)
      .attr('stroke', 'grey')
      .attr('stroke-width', 1);

    if (markerId) {
      line.attr('marker-end', `url(#${markerId})`);
    }

    return line;
  }

  createGridLine(
    svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
    startX: number,
    startY: number,
    endX: number,
  ) {
    const GRID_LINE_OFFSET = 20;
    const horizontalLine = svg
      .append('line')
      .attr('x1', startX)
      .attr('y1', startY + GRID_LINE_OFFSET)
      .attr('x2', endX)
      .attr('y2', startY + GRID_LINE_OFFSET)
      .attr('stroke', 'grey')
      .attr('stroke-width', 1);

    const verticalLine1 = svg
      .append('line')
      .attr('x1', startX)
      .attr('y1', startY)
      .attr('x2', startX)
      .attr('y2', startY + GRID_LINE_OFFSET)
      .attr('stroke', 'grey')
      .attr('stroke-width', 1);

    const verticalLine2 = svg
      .append('line')
      .attr('x1', endX)
      .attr('y1', startY)
      .attr('x2', endX)
      .attr('y2', startY + GRID_LINE_OFFSET)
      .attr('stroke', 'grey')
      .attr('stroke-width', 1)
      .attr('marker-end', 'url(#arrow)')
      .attr('transform', 'rotate(180 ' + endX + ',' + (startY + GRID_LINE_OFFSET / 2) + ')');

    return { horizontalLine, verticalLine1, verticalLine2 };
  }
}
