import {
  Component,
  ElementRef,
  QueryList,
  ViewChildren,
  AfterViewInit,
  OnDestroy,
  Input,
  ViewChild,
  AfterViewChecked,
  HostListener,
} from '@angular/core';
import * as d3 from 'd3';
import { MatIcon } from '@angular/material/icon';
import { TranslocoDirective } from '@jsverse/transloco';
import { LineDrawingService } from '../../../../../core/services/line-drawing.service';
import { PhaseUiModel } from '../../models/LearningPlanInstanceDetailUIModel';
import { CommonModule } from '@angular/common';
import { LinkComponent } from '../../../../../shared/components/link/link.component';

@Component({
  selector: 'app-learning-plan-structure',
  standalone: true,
  imports: [MatIcon, TranslocoDirective, CommonModule, LinkComponent],
  templateUrl: './learning-plan-structure.component.html',
})
export class LearningPlanStructureComponent implements AfterViewInit, OnDestroy, AfterViewChecked {
  @ViewChildren('courseInstanceElement') courseInstanceElements!: QueryList<ElementRef<HTMLElement>>;
  @ViewChild('scrollableContainer') scrollableContainer!: ElementRef<HTMLElement>;

  @Input() phases: PhaseUiModel[] = [];

  private svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  private lines: Array<d3.Selection<SVGLineElement, unknown, null, undefined>> = [];

  constructor(private readonly lineDrawingService: LineDrawingService) {}

  ngAfterViewInit(): void {
    const container = this.scrollableContainer.nativeElement;
    const courseElements = this.courseInstanceElements.toArray();

    // Calculate the total width by summing up the width of each element
    const SVG_CONTAINER_WIDTH = courseElements.reduce((acc, el) => {
      const element = el.nativeElement;
      return acc + element.getBoundingClientRect().width;
    }, 0);

    this.svg = this.lineDrawingService.createSvgContainer(
      container,
      SVG_CONTAINER_WIDTH,
      container.getBoundingClientRect().height,
    );
  }

  ngAfterViewChecked(): void {
    if (!this.lines.length) {
      this.draw(this.courseInstanceElements.toArray());
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.clearLines();
    this.draw(this.courseInstanceElements.toArray()); // Redraw the lines on resize
  }

  private draw(elements: Array<ElementRef<HTMLElement>>) {
    const courseElements = elements.map((el) => el.nativeElement);
    const markerId = 'arrow';
    this.lineDrawingService.createArrowMarker(this.svg, markerId);

    courseElements.forEach((startElement, index) => {
      if (this.phases[index].nextPhaseId === null) {
        // No next phase, skip drawing
        return;
      }

      const isLastElement = index === courseElements.length - 1;
      const endIndex = (index + 1) % courseElements.length;
      const endElement = courseElements[endIndex];

      if (!isLastElement) {
        this.drawLineToNextElement(startElement, endElement, markerId);
      } else {
        this.drawLoopToFirstElement(startElement, endElement);
      }
    });
  }

  private drawLineToNextElement(startElement: HTMLElement, endElement: HTMLElement, markerId: string): void {
    const startX = startElement.offsetLeft + startElement.offsetWidth;
    const startY = startElement.offsetTop + startElement.offsetHeight / 2;

    const endX = endElement.offsetLeft;
    const endY = endElement.offsetTop + endElement.offsetHeight / 2;

    const line = this.lineDrawingService.createLine(this.svg, startX, startY, endX, endY, markerId);
    this.lines.push(line);
  }

  private drawLoopToFirstElement(startElement: HTMLElement, endElement: HTMLElement): void {
    const startY = startElement.offsetTop + startElement.offsetHeight;
    const startX = startElement.offsetLeft + startElement.offsetWidth / 2;
    const endX = endElement.offsetLeft + endElement.offsetWidth / 2;

    const { horizontalLine, verticalLine1, verticalLine2 } = this.lineDrawingService.createGridLine(
      this.svg,
      startX,
      startY,
      endX,
    );
    this.lines.push(verticalLine1, verticalLine2, horizontalLine);
  }

  private clearLines(): void {
    this.lines.forEach((line) => line.remove());
    this.lines = [];
  }

  ngOnDestroy(): void {
    this.clearLines();
  }
}
